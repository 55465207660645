<template>
  <Layout>
    <PageHeader :items="items" />

    <!-- For Supre Admins -->

    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2">
          <div class="row mt-3">
            <div
                v-if="
                this.$storageData.profile.empTypeID == 5 ||
                  this.$storageData.profile.empTypeID == 6
              "
                class="col-md-2"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Country
              </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getCorporateCities()"
              ></multiselect>
            </div>
            <div
                v-if="
                this.$storageData.profile.empTypeID == 5 ||
                  this.$storageData.profile.empTypeID == 6
              "
                class="col-md-2"
            >
              <label style="font-size: 12px">Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getCorporateBranchByCorpID()"
              ></multiselect>
            </div>
            <div
                v-if="
                this.$storageData.profile.empTypeID == 5 ||
                  this.$storageData.profile.empTypeID == 6
              "
                class="col-md-3"
            >
              <label style="font-size: 12px">Select Corporate Branch </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="branches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
                  @input="onchangeBranch()"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>

            <!-- For super Admins -->
            <div
                v-if="
                this.$storageData.profile.empTypeID == 1 ||
                  this.$storageData.profile.empTypeID == 5 ||
                  this.$storageData.profile.empTypeID == 9
              "
                class="col-md-2"
                style="width: 12%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Country
              </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getAllCities()"
              ></multiselect>
            </div>
            <div
                v-if="
                this.$storageData.profile.empTypeID == 1 ||
                  this.$storageData.profile.empTypeID == 9
              "
                class="col-md-2"
                style="width: 12%"
            >
              <label style="font-size: 12px">Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getCorporateList()"
              ></multiselect>
            </div>
            <div
                v-if="
                this.$storageData.profile.empTypeID == 1 ||
                  this.$storageData.profile.empTypeID == 9
              "
                class="col-md-2"
                style="width: 20%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Corporate
              </label>
              <multiselect
                  v-model="corporateID"
                  :options="corporateArr"
                  :show-labels="false"
                  label="corporateName"
                  track-by="corporateID"
                  @input="getCorporateBranchByCorpID()"
              ></multiselect>
            </div>

            <div
                v-if="
                this.$storageData.profile.empTypeID == 1 ||
                  this.$storageData.profile.empTypeID == 9
              "
                class="col-md-2"
                style="width: 20%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Corporate Branch
              </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="branches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
                  @input="onchangeBranch()"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>

            <!-- For priciples -->
            <div
                v-if="
                login_type == 4 ||
                  this.$storageData.profile.empTypeID == 7 ||
                  this.$storageData.profile.empTypeID == 18 ||
                  ((this.$storageData.profile.empTypeID == 8 ||
                    this.$storageData.profile.empTypeID == 21) &&
                    login_type == 4)
              "
                class="col-md-2"
                style="width: 12%"
            >
              <label style="font-size: 12px">Select Country </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getPrincipleCities()"
              ></multiselect>
            </div>
            <div
                v-if="
                login_type == 4 ||
                  this.$storageData.profile.empTypeID == 7 ||
                  this.$storageData.profile.empTypeID == 18 ||
                  ((this.$storageData.profile.empTypeID == 8 ||
                    this.$storageData.profile.empTypeID == 21) &&
                    login_type == 4)
              "
                class="col-md-2"
                style="width: 12%"
            >
              <label style="font-size: 12px">Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getPrincipleBranchByPrincipleID()"
              ></multiselect>
            </div>
            <div
                v-if="
                login_type == 4 ||
                  this.$storageData.profile.empTypeID == 7 ||
                  this.$storageData.profile.empTypeID == 18 ||
                  ((this.$storageData.profile.empTypeID == 8 ||
                    this.$storageData.profile.empTypeID == 21) &&
                    login_type == 4)
              "
                class="col-md-2"
                style="width: 20%"
            >
              <label style="font-size: 12px">Select Principle Branch </label>
              <multiselect
                  v-model="principleBranchID"
                  :options="principleBranches"
                  :show-labels="false"
                  label="principleBranchName"
                  track-by="principleBranchID"
                  @input="getPrincipleCorporateBranches()"
              ></multiselect>
            </div>
            <div
                v-if="
                login_type == 4 ||
                  this.$storageData.profile.empTypeID == 7 ||
                  this.$storageData.profile.empTypeID == 18 ||
                  ((this.$storageData.profile.empTypeID == 8 ||
                    this.$storageData.profile.empTypeID == 21) &&
                    login_type == 4)
              "
                class="col-md-2"
                style="width: 20%"
            >
              <label class="form-label" style="font-size: 12px"
              >Select Corporate Branch
              </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="branches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
                  @input="onchangeBranch()"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
            <!-- For priciples -->
            <div class="col-md-4">
              <label
                  class="form-label"
                  for="formrow-endTime-input"
                  style="font-size: 12px"
              >Select Restaurant Branch</label
              >
              <multiselect
                  v-model="restBranch"
                  :options="cafeteriaArr"
                  :show-labels="false"
                  label="restaurantName"
                  track-by="restBranchID"
              ></multiselect>
            </div>
            <div class="col-sm-2 col-md-3">
              <label
                  class="form-label"
                  for="formrow-date-input"
                  style="font-size: 12px"
              >Date Filter</label
              >
              <date-picker
                  v-model="dateFilter"
                  append-to-body
                  format="DD MMM Y"
                  lang="en"
                  range
                  value-type="format"
              ></date-picker>
            </div>
            <!-- Apply filter -->
            <div class="col-md-2" style="width: auto; margin-top: 21px">
              <button class="btn btn-themeOrange" v-on:click="applyFilter()">
                Apply Filter
              </button>
            </div>
            <!-- Apply filter END-->

            <!-- Clear filter -->
            <div class="col-md-2" style="width: 16%; margin-top: 21px">
              <button class="btn btn-themeBrown" v-on:click="clearFilter()">
                Clear Filter
              </button>
            </div>
            <!-- Clear filter END-->
            <!-- <div class="col-md-1 mt-3">
          <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear </button>
      </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div v-if="isLoading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
              content-class="p-3 text-muted"
              justified
              nav-class="nav-tabs-custom"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Overview</span>
              </template>

              <div class="row">
                <div
                    v-if="
                    login_type == 1 ||
                      login_type == 3 ||
                      login_type == 4
                  "
                    class="col-md-6 col-xl-3"
                >
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{
                            $func.formatPrice(
                                cardData.totalSum ? cardData.totalSum : 0
                            )
                          }}
                        </h4>
                        <p class="text-muted mb-0">Credit Meal Plan</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{
                            $func.formatCommaSepNum(
                                cardData.totalVeg ? cardData.totalVeg : 0
                            )
                          }}
                        </h4>
                        <p class="text-muted mb-0">Veg Count</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{
                            $func.formatCommaSepNum(
                                cardData.totalNonVeg ? cardData.totalNonVeg : 0
                            )
                          }}
                        </h4>
                        <p class="text-muted mb-0">Non-Veg Count</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3" v-if="proformaInvoices.length > 0 && $can('view meal plan proforma invoice')">
                  <div class="card">
                    <div class="card-body">
                     <div v-for="data in proformaInvoices" :key="data.id">
                       <router-link
                           :to="{
                      name: 'MealPlanOrderDetail',
                      params: { id: data.id, type: 'view' },
                    }"
                       >
                         <button class="btn btn-themeOrange btn-sm">
                           {{data.report_date_from +' - '+data.report_date_till }} <br> View Invoice
                         </button>
                        </router-link>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  cards end -->
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">List</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div

                            class="col-sm-12 col-md-4"
                            style="margin-bottom: 15px;"
                        >
                          <div class="btn-group" role="group" v-if="login_type == 1">
                            <button
                                class="btn btn-themeBrown"
                                type="button"
                                @click="downloadSample()"
                            >
                              Excel
                            </button>
                          </div>
                          <div class="btn-group p-1" role="group" v-if="$can('generate meal plan proforma invoice')">
                            <button
                                class="btn btn-themeBrown"
                                type="button"
                                @click="generateProformaInvoice()"
                            >
                              Generate Proforma Invoice
                            </button>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div
                              id="tickets-table_length"
                              class="dataTables_length"
                          >
                            <label class="d-inline-flex align-items-center">
                              Show &nbsp;&nbsp;
                              <b-form-select
                                  v-model="perPage"
                                  :options="pageOptions"
                                  size="sm"
                                  style="margin-left: 5px; margin-right: 5px"
                              ></b-form-select
                              >&nbsp;&nbsp;&nbsp; entries
                            </label>
                          </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-4 row">
                          <div
                              id="tickets-table_filter"
                              class="dataTables_filter text-md-end"
                          >
                            <label class="d-inline-flex align-items-center">
                              Search:
                              <b-form-input
                                  v-model="filter"
                                  class="form-control form-control-sm ms-2"
                                  placeholder="Search..."
                                  type="search"
                              ></b-form-input>
                            </label>
                          </div>
                        </div>
                        <!-- End search -->
                      </div>
                      <!-- <div class="row">

                                              <div class="col-sm-6 col-md-3">
                                                  <label class="form-label" for="formrow-date-input">Date Filter</label>
                                                  <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>

                                              </div>
                                              <div class="col-sm-6 col-md-2 mt-3">
                                                  <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear Filter </button>
                                              </div>
                                      </div> -->
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                            :busy="isTableBusy"
                            :current-page="currentPage"
                            :fields="fields"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            :items="tableData"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            bordered
                            foot-clone
                            hover
                            outlined
                            responsive
                            striped
                            @filtered="onFiltered"
                        >
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>
                          <template v-slot:cell(date)="data">
                            <router-link
                                :to="{
                                name: 'mealPlanStatisticsTable',
                                params: {
                                  date: data.item.created_at,
                                  corpID: corporateID.corporateID,
                                  corpBranchID: data.item.corpBranchID,
                                  restBranchID: data.item.restBranchID,
                                  corpMealID: data.item.corpMealID,
                                  type: 'filtered'
                                }
                              }"
                            >
                              <span v-if="data.item.date"
                              >{{ data.item.date }}
                              </span>
                            </router-link>
                          </template>
                          <template v-slot:head(restaurant)>
                            <span>Restaurant Name</span>
                          </template>
                          <template v-slot:cell(restaurant)="data">
                            <span :title="data.item.restaurant">{{
                                data.item.restaurant
                              }}</span>
                          </template>
                          <template v-slot:head(corporateName)>
                            <span>Corporate Name</span>
                          </template>
                          <template v-slot:cell(corporateName)="data">
                            <span :title="data.item.corporateName">{{
                                data.item.corporateName
                              }}</span>
                          </template>
                          <template v-slot:head(ratePerPersonVeg)>
                            <span>Rate Veg</span>
                          </template>
                          <template v-slot:head(mealPlanName)>
                            <span>Meal</span>
                          </template>
                          <template v-slot:head(minGuaranteeVeg)>
                            <span>Min Guar Veg</span>
                          </template>
                          <template v-slot:head(onlineOrderVegCount)>
                            <span>Online Veg Count</span>
                          </template>
                          <template v-slot:head(offlineVegCount)>
                            <span>Offline Veg Count</span>
                          </template>
                          <template v-slot:head(totalVegCount)>
                            <span>Total Veg Count</span>
                          </template>
                          <template v-slot:head(varianceVeg)>
                            <span>Variance Veg</span>
                          </template>
                          <template v-slot:head(rateVeg)>
                            <span>Rate Veg</span>
                          </template>
                          <template #cell(rateVeg)="data">
                            <span>{{ formatPrice(data.item.rateVeg) }}</span>
                          </template>
                          <template v-slot:head(billedVeg)>
                            <span>Qty to Billed Veg</span>
                          </template>
                          <template v-slot:head(salesVeg)>
                            <span>Sales Veg</span>
                          </template>
                          <template #cell(salesVeg)="data">
                            <span>{{ formatPrice(data.item.salesVeg) }}</span>
                          </template>
                          <template v-slot:head(minGuaranteeNonVeg)>
                            <span>Min Guar Non Veg</span>
                          </template>
                          <template v-slot:head(onlineOrderNonVegCount)>
                            <span>Online Non Veg Count</span>
                          </template>
                          <template v-slot:head(offlineNonVegCount)>
                            <span>Offline Non Veg Count</span>
                          </template>
                          <template v-slot:head(totalNonVegCount)>
                            <span>Total Non Veg Count</span>
                          </template>
                          <template v-slot:head(varianceNonVeg)>
                            <span>Variance Non Veg</span>
                          </template>
                          <template v-slot:head(rateNonVeg)>
                            <span>Rate Non Veg</span>
                          </template>
                          <template #cell(rateNonVeg)="data">
                            <span>{{ formatPrice(data.item.rateNonVeg) }}</span>
                          </template>
                          <template v-slot:head(billedNonVeg)>
                            <span>Qty to Billed Non Veg</span>
                          </template>
                          <template v-slot:head(salesNonVeg)>
                            <span>Sales Non Veg</span>
                          </template>
                          <template #cell(salesNonVeg)="data">
                            <span>{{
                                formatPrice(data.item.salesNonVeg)
                              }}</span>
                          </template>
                          <template v-slot:head(total)>
                            <span>Sub Total</span>
                          </template>
                          <template #cell(total)="data">
                            <span>{{ formatPrice(data.item.total) }}</span>
                          </template>
                          <template v-slot:head(cgst)>
                            <span>CGST</span>
                          </template>
                          <template #cell(cgst)="data">
                            <span>{{ formatPrice(data.item.cgst) }}</span>
                          </template>
                          <template v-slot:head(sgst)>
                            <span>SGST</span>
                          </template>
                          <template #cell(sgst)="data">
                            <span>{{ formatPrice(data.item.sgst) }}</span>
                          </template>
                          <template v-slot:head(grandTotal)>
                            <span>Grand Total</span>
                          </template>
                          <template #cell(grandTotal)="data">
                            <span>{{ formatPrice(data.item.grandTotal) }}</span>
                          </template>
                          <template #cell(Action)="data">
                            <div>


                              <i v-if="login_type == 1"
                                  class="uil uil-edit-alt"
                                  style="
                                  font-size: 19px;
                                  margin-left: 10px;
                                  color: #f3766a;
                                "
                                  title="View"
                                  @click="openModal(data.item.mealPlanReportID)"
                              ></i>
                            </div>

                          </template>
                          <template v-slot:foot(reportDay)>
                            <span>Total: </span>
                          </template>
                          <template v-slot:foot(date)>
                            <span></span>
                          </template>
                          <template v-slot:foot(restaurant)>
                            <span></span>
                          </template>
                          <template v-slot:foot(corporateName)>
                            <span></span>
                          </template>
                          <template v-slot:foot(mealPlanName)>
                            <span></span>
                          </template>
                          <template v-slot:foot(minGuaranteeVeg)>
                            <span>{{
                                formatNumber(overViewData.minGuaranteeVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(onlineOrderVegCount)>
                            <span>{{
                                formatNumber(
                                    overViewData.onlineOrderVegCountTotal
                                )
                              }}</span>
                          </template>
                          <template v-slot:foot(offlineVegCount)>
                            <span>{{
                                formatNumber(overViewData.offlineVegCountTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(totalVegCount)>
                            <span>{{
                                formatNumber(overViewData.totalCountVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(varianceVeg)>
                            <span>{{
                                formatNumber(overViewData.varianceVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(rateVeg)>
                            <span>{{
                                formatPrice(overViewData.rateVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(billedVeg)>
                            <span>{{
                                formatNumber(overViewData.billedVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(salesVeg)>
                            <span>{{
                                formatPrice(overViewData.salesVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(minGuaranteeNonVeg)>
                            <span>{{
                                formatNumber(overViewData.minGuaranteeNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(onlineOrderNonVegCount)>
                            <span>{{
                                formatNumber(
                                    overViewData.onlineOrderNonVegCountTotal
                                )
                              }}</span>
                          </template>
                          <template v-slot:foot(offlineNonVegCount)>
                            <span>{{
                                formatNumber(overViewData.offlineNonVegCountTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(totalNonVegCount)>
                            <span>{{
                                formatNumber(overViewData.totalCountNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(varianceNonVeg)>
                            <span>{{
                                formatNumber(overViewData.varianceNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(rateNonVeg)>
                            <span>{{
                                formatPrice(overViewData.rateNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(billedNonVeg)>
                            <span>{{
                                formatNumber(overViewData.billedNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(salesNonVeg)>
                            <span>{{
                                formatPrice(overViewData.salesNonVegTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(total)>
                            <span>{{
                                formatPrice(overViewData.totalsumTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(cgst)>
                            <span>{{
                                formatPrice(overViewData.cgstTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(sgst)>
                            <span>{{
                                formatPrice(overViewData.sgstTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(grandTotal)>
                            <span>{{
                                formatPrice(overViewData.grandTotalTotal)
                              }}</span>
                          </template>
                          <template v-slot:foot(Action)>
                            <p style="display: none">Action</p>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                              class="
                              dataTables_paginate
                              paging_simple_numbers
                              float-end
                            "
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                  v-model="currentPage"
                                  :per-page="perPage"
                                  :total-rows="rows"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!--Proforma modals -->
    <b-modal
        id="modal-lg-proforma"
        v-model="profModal"
        title="Generate Proforma Invoice"
        ok-title = "Generate"
        @ok="submitProformaRequest()"
        size="md"
    >
      <div class="row">
            <div class="col-md-6 mb-2">
                <label class="form-label">Mode/Term Of Payment</label>
                <input
                    v-model="paymentMode"
                    class="form-control"
                    type="text"
                />
            </div>
            <div class="col-md-6 mb-2">
                <label class="form-label">Buyer's Order No</label>
                <input
                    v-model="orderNo"
                    class="form-control"
                    type="text"
                />

            </div>
            <div class="col-md-6">
              <label class="form-label" >Sez Supply </label>
              <multiselect v-model="sezSupply" :options="sezArr" :show-labels="false"></multiselect>
            </div>
            <div class="col-md-6">
              <label class="form-label" >Sell For </label>
              <multiselect v-model="saleFor" :options="saleArr" :show-labels="false"></multiselect>
            </div>
      </div>
    </b-modal>
    <!--End OF Proforma Modal -->
    <b-modal
        id="modal-lg-addPrincipalEmployee"
        v-model="sectionModal"
        hide-footer
    >
      <div class="row">
        <div class="col-sm-6">
          <span
          ><b>Date :&nbsp;{{ mealPlanData.date }}</b></span
          >
        </div>
        <div class="col-sm-6" style="text-align: right;">
          <span
          ><b>Report Day :&nbsp;{{ mealPlanData.reportDay }}</b></span
          >
        </div>
        <div class="col-sm-12">
          <span
          ><b>Corporate Name: {{ mealPlanData.corporateName }}</b></span
          >
        </div>
        <div class="col-sm-12">
          <span
          ><b>Restaurant Name : {{ mealPlanData.restaurant }}</b></span
          >
        </div>
      </div>
      <div class="row">
        <form method="post">
          <div class="row">
            <div class="col-lg-12">
              <!-- for veg -->
              <div class="card p-2 mt-2">
                <h5>Veg</h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Minimum Guarantee Veg*</label
                      >
                    </div>
                    <div class="col-md-3">
                      <input
                          id="formrow-name-input"
                          v-model="minGuaranteeVeg"
                          :class="{
                          'is-invalid': submitted && $v.minGuaranteeVeg.$error
                        }"
                          class="form-control"
                          type="number"
                          v-on:keyup="keymonitor"
                      />
                      <div
                          v-if="submitted && $v.minGuaranteeVeg.$error"
                          class="invalid-feedback"
                      >
                        <span v-if="!$v.minGuaranteeVeg.required"
                        >This value is required.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-name-input"
                      >Online Count Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="online_count_veg">{{
                          $func.formatNumber(onlineOrderVegCount)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label">Offline Count Veg*</label>
                    </div>
                    <div class="col-md-3">
                      <input
                          id="offline_count_veg"
                          v-model="offlineVegCount"
                          :class="{
                          'is-invalid': submitted && $v.offlineVegCount.$error
                        }"
                          class="form-control"
                          name="totalCount"
                          type="number"
                          v-on:keyup="keymonitor"
                      />
                      <div
                          v-if="submitted && $v.offlineVegCount.$error"
                          class="invalid-feedback"
                      >
                        <span v-if="!$v.offlineVegCount.required"
                        >This value is required.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label">Total Veg Count</label>
                    </div>
                    <div
                        class="col-md-3"
                        for="formrow-totalCount-input"
                        style="text-align: right"
                    >
                      <span id="total_veg_count">{{
                          $func.formatNumber(totalVegCount ? totalVegCount : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Quantity to Billed Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="qantity_to_billed_veg">{{
                          $func.formatNumber(qtyBilledVeg ? qtyBilledVeg : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Rate Veg</label
                      >
                    </div>
                    <div
                        class="col-md-3"
                        style="margin-bottom: 5px; text-align: right"
                    >
                      <span>{{
                          $func.formatPrice(rateVeg ? rateVeg : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Sales Veg</label
                      >
                    </div>
                    <div
                        id="sales_veg"
                        class="col-md-3"
                        style="text-align: right"
                    >
                      <span id="sales_veg">{{
                          $func.formatPrice(amountVeg ? amountVeg : 0)
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- for nonveg -->
              <div class="card p-2 mt-2">
                <h5>Non Veg</h5>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Minimum Guarantee Non-Veg*</label
                      >
                    </div>
                    <div class="col-md-3">
                      <input
                          id="formrow-name-input"
                          v-model="minGuaranteeNonVeg"
                          :class="{
                          'is-invalid':
                            submitted && $v.minGuaranteeNonVeg.$error
                        }"
                          class="form-control"
                          type="number"
                          v-on:keyup="forNonveg"
                      />
                      <div
                          v-if="submitted && $v.minGuaranteeNonVeg.$error"
                          class="invalid-feedback"
                      >
                        <span v-if="!$v.minGuaranteeNonVeg.required"
                        >This value is required.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Online Count Non-Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="online_count_nonveg">{{
                          $func.formatNumber(onlineOrderNonVegCount)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Offline Count Non-Veg*</label
                      >
                    </div>
                    <div class="col-md-3">
                      <input
                          id="offline_count_nonveg"
                          v-model="offlineNonVegCount"
                          :class="{
                          'is-invalid':
                            submitted && $v.offlineNonVegCount.$error
                        }"
                          class="form-control"
                          type="number"
                          v-on:keyup="forNonveg"
                      />
                      <div
                          v-if="submitted && $v.offlineNonVegCount.$error"
                          class="invalid-feedback"
                      >
                        <span v-if="!$v.offlineNonVegCount.required"
                        >This value is required.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Total Non-Veg Count</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="total_nonveg_count">{{
                          $func.formatNumber(
                              totalNonVegCount ? totalNonVegCount : 0
                          )
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Quantity to Billed Non-Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="qantity_to_billed_nonveg">
                        {{
                          $func.formatNumber(billedNonVeg ? billedNonVeg : 0)
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9" style="margin-bottom: 5px">
                      <label class="form-label" for="formrow-empName-input"
                      >Rate Non-Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span>{{
                          $func.formatPrice(rateNonVeg ? rateNonVeg : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div id="sales_nonveg" class="col-md-9">
                      <label class="form-label" for="formrow-empName-input"
                      >Sales Non-Veg</label
                      >
                    </div>
                    <div class="col-md-3" style="text-align: right">
                      <span id="sales_nonveg">{{
                          $func.formatPrice(amountNonVeg ? amountNonVeg : 0)
                        }}</span>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
              <!-- for Total -->
              <div class="card p-2 mt-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6" style="margin-bottom: 5px">
                      <label class="form-label" for="formrow-empName-input"
                      >Sub Total</label
                      >
                    </div>
                    <div
                        id="sub_total"
                        class="col-md-6"
                        style="text-align: right"
                    >
                      <span id="sub_total">{{
                          $func.formatPrice(
                              mealPlanSubTotal ? mealPlanSubTotal : 0
                          )
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" style="margin-bottom: 5px">
                      <label class="form-label" for="formrow-empName-input"
                      >Cgst</label
                      >
                    </div>
                    <div id="cgst" class="col-md-6" style="text-align: right">
                      <span id="cgst">{{
                          $func.formatPrice(mealPlanCgst ? mealPlanCgst : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" style="margin-bottom: 5px">
                      <label class="form-label" for="formrow-empName-input"
                      >Sgst</label
                      >
                    </div>
                    <div id="sgst" class="col-md-6" style="text-align: right">
                      <span id="cgst">{{
                          $func.formatPrice(mealPlanSgst ? mealPlanSgst : 0)
                        }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" style="margin-bottom: 5px">
                      <label class="form-label" for="formrow-empName-input">
                        Grand Total</label
                      >
                    </div>
                    <div
                        id="grand_total"
                        class="col-md-6"
                        style="text-align: right"
                    >
                      <span id="grand_total">{{
                          $func.formatPrice(
                              mealPlanGrandTotal ? mealPlanGrandTotal : 0
                          )
                        }}</span>
                    </div>
                  </div>

                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p v-show="showAuthError" style="color: red; display: none">
                    Please enter Values
                  </p>
                </div>
                <div class="col-6">
                  <b-spinner
                      v-if="loading"
                      class="m-2 col-3"
                      role="status"
                      variant="primary"
                  ></b-spinner>
                  <button
                      v-if="canEdit"
                      :disabled="loading"
                      class="
                      col-3
                      btn btn-themeYellow
                      w-md
                      waves-effect waves-light
                    "
                      style="float: right; padding: 10px"
                      type="button"
                      @click="updateMealPlanDataByMealID()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <OrderReciept
        :orderDetails="this.orderDetails"
        :orderStatusArr="this.orderStatusArr"
        :orderedItemArr="this.orderedItemArr"
    >
    </OrderReciept>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import countTo from "vue-count-to";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

export default {
  page: {
    title: "Meal Plan Statistics",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, OrderReciept },
  data() {
    return {
      isTableBusy: false,
      showAuthError: false,
      login_type:0,
      total: "",
      cgst: "",
      sgst: "",
      canEdit: true,
      // for veg
      minGuaranteeVeg: "",
      onlineOrderVegCount: "",
      offlineVegCount: "",
      totalVegCount: "",
      qtyBilledVeg: "",
      // for Non-veg
      minGuaranteeNonVeg: "",
      onlineOrderNonVegCount: "",
      offlineNonVegCount: "",
      totalNonVegCount: "",
      billedNonVeg: "",
      mealPlanSubTotal: "",
      mealPlanCgst: "",
      mealPlanSgst: "",
      mealPlanGrandTotal: "",
      sectionModal: false,
      profModal:false,
      mealPlanData: "",
      showDismissibleAlert: false,
      alertMsg: "Meal Plan Update Successfully!",
      tableData: [],
      overViewData: [],
      cardData: [],
      templateArr: [],
      restBranchArr: [],
      restBranchID: "",
      branches: [],
      branchID: "",
      principleBranchID: "",
      principleBranches: [],
      corporateArr: [],
      corporateID: "",
      cafeteriaArr: [],
      restBranch: "",
      countryArr: [],
      country: "",
      orderDetails: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      dateFilter: [],
      title: "Meal Plan Statistics",
      items: [
        {
          text: "View"
        },
        {
          text: "Meal Plan Statistics",
          active: true
        }
      ],
      corpBranchID: "",
      cityArr: [],
      city: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      excelFields: {},
      isLoading: 0,
      fields: [
        {
          key: "Action",
          label: "Action"
        },
        {
          key: "reportDay",
          label: "Report Day",
          sortable: true
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        {
          key: "restaurant",
          label: "Restaurant",
          sortable: true
        },
        {
          key: "corporateName",
          label: "Corporate",
          sortable: true
        },
        {
          key: "mealPlanName",
          label: "Meal",
          sortable: true
        },
        {
          key: "minGuaranteeVeg",
          label: "Min Guar Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "onlineOrderVegCount",
          label: "Online Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "offlineVegCount",
          label: "Offline Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalVegCount",
          label: "Total Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceVeg",
          label: "Variance Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "rateVeg",
          label: "Rate Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "billedVeg",
          label: "Qty to Billed Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "salesVeg",
          label: "Sales Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "minGuaranteeNonVeg",
          label: "Min Guar Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "onlineOrderNonVegCount",
          label: "Online Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "offlineNonVegCount",
          label: "Offline Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalNonVegCount",
          label: "Total Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceNonVeg",
          label: "Variance Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "rateNonVeg",
          label: "Rate Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "billedNonVeg",
          label: "Qty to Billed Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "salesNonVeg",
          label: "Sales Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "total",
          label: "Sub Total",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "cgst",
          label: "CGST",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "sgst",
          label: "SGST",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "grandTotal",
          label: "Grand Totla",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      paymentMode:"",
      sezArr: [
        "Yes","No"
      ],
      sezSupply:"",
      saleArr: [
        "CloudKitch","Flavourly"
      ],
      saleFor:"",
      orderNo:"",
      proformaInvoices:[],
    };
  },
  created() {
    this.corpBranchID =
      this.$storageData.profile.empTypeID == 5 ? this.brandLogin() : "";
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },

  mounted() {
    this.login_type=this.$storageData.login_type;
    // Set the initial number of items
    if (this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 9) {
      this.getAllCountry();
    } else if (
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getCorporateCountry();
    } else if (this.$storageData.login_type == 4) {
      this.getPrincipleCountry();
    } else if (
        this.$storageData.login_type == 3 &&
      this.$storageData.profile.empTypeID == 6
    ) {
      this.getCorporateCountry();
    }
    this.totalRows = this.items.length;

    // this.readMealPlanReport();

    if (this.$storageData.login_type == 2) {
      this.fields = [
        {
          key: "reportDay",
          label: "Report Day",
          sortable: true
        },
        {
          key: "date",
          label: "Date",
          sortable: true
        },
        {
          key: "restaurant",
          label: "Restaurant",
          sortable: true
        },
        {
          key: "mealPlanName",
          label: "Meal",
          sortable: true
        },
        {
          key: "minGuaranteeVeg",
          label: "Min Guar Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "onlineOrderVegCount",
          label: "Online Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "offlineVegCount",
          label: "Offline Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalVegCount",
          label: "Total Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceVeg",
          label: "Variance Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "billedVeg",
          label: "Qty to Billed Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "minGuaranteeNonVeg",
          label: "Min Guar Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "onlineOrderNonVegCount",
          label: "Online Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "offlineNonVegCount",
          label: "Offline Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalNonVegCount",
          label: "Total Non Veg Count",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "varianceNonVeg",
          label: "Variance Non Veg",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "billedNonVeg",
          label: "Qty to Billed Non Veg",
          sortable: true,
          tdClass: "align-right"
        }
      ];
    }
  },
  methods: {
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          // this.country = (this.countryArr) ? this.countryArr[0] : "";
          // this.getAllCities();
        });
    },

    totalCalculation() {
      var sales_value_veg = this.amountVeg; // document.getElementById("sales_veg").value;
      var sales_value_nonveg = this.amountNonVeg; // document.getElementById("sales_nonveg").value;
      this.mealPlanSubTotal = sales_value_veg + sales_value_nonveg;

      this.mealPlanCgst = this.mealPlanSubTotal * 0.025;
      this.mealPlanSgst = this.mealPlanSubTotal * 0.025;
      this.mealPlanGrandTotal =
        this.mealPlanSubTotal + this.mealPlanCgst + this.mealPlanSgst;

      document.getElementById("sub_total").value = this.mealPlanSubTotal;
      document.getElementById("cgst").value = this.mealPlanCgst;
      document.getElementById("sgst").value = this.mealPlanSgst;
      document.getElementById("grand_total").value = this.mealPlanGrandTotal;

      // document.getElementById("grand_total").value =  parseFloat(this.mealPlanSubTotal) + parseFloat(this.mealPlanCgst) + parseFloat(this.mealPlanSgst);
    },

    updateMealPlanDataByMealID() {
      let apiName = this.$loggedRole+"/updateMealPlanDataByMealID";
      let formData = new FormData();
      //  alert();
      // console.log(document.getElementById("sales_veg").value);
      // console.log(this.minGuaranteeVeg);

      if (this.minGuaranteeVeg === "") {
        this.showAuthError = true;
        return false;
      } else {
        this.showAuthError = false;
      }

      if (document.getElementById("offline_count_veg").value === "") {
        this.showAuthError = true;
        return false;
      } else {
        this.showAuthError = false;
      }

      if (this.minGuaranteeNonVeg === "") {
        this.showAuthError = true;
        return false;
      } else {
        this.showAuthError = false;
      }

      if (document.getElementById("offline_count_nonveg").value === "") {
        this.showAuthError = true;
        return false;
      } else {
        this.showAuthError = false;
      }

      this.alertMsg = "Details Updated Successfully !";
      this.$root.$emit("bv::hide::modal", "modal-lg-addPrincipalEmployee");
      formData.append("mealPlanReportID", this.mealPlanReportID);
      formData.append("minGuaranteeVeg", this.minGuaranteeVeg);
      // formData.append("online_count_veg", this.onlineOrderVegCount);
      formData.append(
        "offline_count_veg",
        document.getElementById("offline_count_veg").value
      );
      formData.append(
        "total_veg_count",
        document.getElementById("total_veg_count").value
      );
      formData.append(
        "qantity_to_billed_veg",
        document.getElementById("qantity_to_billed_veg").value
      );
      formData.append("sales_veg", document.getElementById("sales_veg").value);
      // //  for veg

      // //  for Non-veg
      formData.append("minGuaranteeNonVeg", this.minGuaranteeNonVeg);
      //formData.append("online_count_nonveg", this.onlineOrderNonVegCount);
      formData.append(
        "offline_count_nonveg",
        document.getElementById("offline_count_nonveg").value
      );
      formData.append(
        "total_nonveg_count",
        document.getElementById("total_nonveg_count").value
      );
      formData.append(
        "qantity_to_billed_nonveg",
        document.getElementById("qantity_to_billed_nonveg").value
      );
      formData.append(
        "sales_nonveg",
        document.getElementById("sales_nonveg").value
      );

      //  //  for Total
      formData.append("sub_total", document.getElementById("sub_total").value);
      formData.append("cgst", document.getElementById("cgst").value);
      formData.append("sgst", document.getElementById("sgst").value);
      formData.append(
        "grand_total",
        document.getElementById("grand_total").value
      );

      formData.append("empID", this.$storageData.profile.pid);
      formData.append("empTypeID", this.$storageData.profile.empTypeID);
      formData.append("loginTypeID", this.$storageData.login_type);
      formData.append("previousData", JSON.stringify(this.mealPlanData));

      this.axios
        .post(apiName, formData)
        .then(result => {
          //  this.status = result.data.data;
          this.showDismissibleAlert = true;
          this.mealPlanData = result.data.data;
          // console.log(this.tableData);
          this.errorMsg = "";

          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);
          this.readMealPlanReport();
        })
        .catch(error => {
          this.loading = false;
          alert(error.response.data.data);
        });
    },

    keymonitor() {
      var online_count_veg = this.onlineOrderVegCount; // document.getElementById("online_count_veg").value;
      var offline_count_veg = document.getElementById("offline_count_veg")
        .value;
      this.totalVegCount = document.getElementById("total_veg_count").value =
        parseFloat(online_count_veg) + parseFloat(offline_count_veg);

      this.qtyBilledVeg = Math.max(this.minGuaranteeVeg, this.totalVegCount);
      document.getElementById(
        "qantity_to_billed_veg"
      ).value = this.qtyBilledVeg;

      // for rate calculation
      this.amountVeg = this.qtyBilledVeg * this.rateVeg;
      document.getElementById("sales_veg").value = this.amountVeg;
      this.totalCalculation();
    },

    forNonveg() {
      var online_count_nonveg = this.onlineOrderNonVegCount; // document.getElementById("online_count_nonveg").value;
      var offline_count_nonveg = document.getElementById("offline_count_nonveg")
        .value;
      this.totalNonVegCount = document.getElementById(
        "total_nonveg_count"
      ).value =
        parseFloat(online_count_nonveg) + parseFloat(offline_count_nonveg);

      this.billedNonVeg = Math.max(
        this.minGuaranteeNonVeg,
        this.totalNonVegCount
      );
      document.getElementById(
        "qantity_to_billed_nonveg"
      ).value = this.billedNonVeg;

      // for rate calculation
      this.amountNonVeg = this.billedNonVeg * this.rateNonVeg;
      document.getElementById("sales_nonveg").value = this.amountNonVeg;
      this.totalCalculation();
    },

    getMealPlanDataByMealID(mealPlanReportID) {
      this.axios
        .post(this.$loggedRole+"/getMealPlanDataByMealID", {
          mealPlanReportID: mealPlanReportID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid
        })
        .then(result => {
          this.mealPlanData = result.data.data;
          this.canEdit = result.data.edited;
          //  For veg
          this.minGuaranteeVeg = result.data.data.minGuaranteeVeg;
          this.onlineOrderVegCount = result.data.data.onlineOrderVegCount;
          this.offlineVegCount = result.data.data.offlineVegCount;
          this.totalVegCount = document.getElementById(
            "total_veg_count"
          ).value = result.data.data.totalVegCount;
          this.rateVeg = result.data.data.rateVeg;
          this.qtyBilledVeg = document.getElementById(
            "qantity_to_billed_veg"
          ).value = result.data.data.qtyBilledVeg;
          this.amountVeg = document.getElementById("sales_veg").value =
            result.data.data.amountVeg;

          //  For Non-veg
          this.minGuaranteeNonVeg = result.data.data.minGuaranteeNonVeg;
          this.onlineOrderNonVegCount = result.data.data.onlineOrderNonVegCount;
          this.offlineNonVegCount = result.data.data.offlineNonVegCount;
          this.totalNonVegCount = document.getElementById(
            "total_nonveg_count"
          ).value = result.data.data.totalNonVegCount;
          this.rateNonVeg = result.data.data.rateNonVeg;
          this.billedNonVeg = document.getElementById(
            "qantity_to_billed_nonveg"
          ).value = result.data.data.qtyBilledNonVeg;
          this.amountNonVeg = document.getElementById("sales_nonveg").value =
            result.data.data.amountNonVeg;

          this.mealPlanSubTotal = document.getElementById("sub_total").value =
            result.data.data.total;
          this.mealPlanCgst = document.getElementById("cgst").value =
            result.data.data.cgst;
          this.mealPlanSgst = document.getElementById("sgst").value =
            result.data.data.sgst;
          this.mealPlanGrandTotal = document.getElementById(
            "grand_total"
          ).value = result.data.data.grandTotal;

          //For Total
          this.total = result.data.data.total;
        });
    },
    openModal(mealPlanReportID) {
      this.$root.$emit("bv::show::modal", "modal-lg-addPrincipalEmployee");
      this.mealPlanReportID = mealPlanReportID;
      this.getMealPlanDataByMealID(this.mealPlanReportID);
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "MealPlanReport"
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "meal-plan-report.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          //this.city = (this.cityArr) ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 5) {
            this.getCorporateBranchByCorpID();
          }
        });
    },
    getCorporateList() {
      this.corporateArr = [];
      this.corporateID = "";
      this.axios
        .post(this.$loggedRole+"/getCorporateLists", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.corporateArr = response.data.data;
        });
    },
    getPrincipleCountry() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {
          principleID: this.$storageData.profile.principleID,
          isDashboard: 1, // for all dropdown in only dashboards
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          // this.country = (this.countryArr) ? this.countryArr[0] : "";
          this.getPrincipleCities();
        });
    },
    getPrincipleCities() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {
          principleID: this.$storageData.profile.principleID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          // this.city = (this.cityArr) ? this.cityArr[0] : "";
          this.getPrincipleBranchByPrincipleID();
        });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          principleID: this.$storageData.profile.principleID,
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
          // this.principleBranchID = this.principleBranches[0];
          // this.principleBranchIDSelected = this.principleBranchID.principleBranchID;
          // console.log(this.principleBranchID);
          // this.getPrincipleCorporateBranches();
        });
    },
    getPrincipleCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCorporateBranches", {
          principleBranchID: this.principleBranchID.principleBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.branches = response.data.data;
          //  this.branchID = (this.branches) ? this.branches[0] : [];
          //  this.principleCorporateStatistics();
        });
    },
    brandLogin() {
      this.getCorporateCountry();
    },
    getCorporateCountry() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {
          corporateID: this.$storageData.profile.corporateID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getCorporateCities();
        });
    },
    getCorporateCities() {
      var corporateID =
        this.corporateID && this.corporateID.corporateID != ""
          ? this.corporateID.corporateID
          : this.$storageData.profile.corporateID;
      var corpBranchID =
        this.$storageData.profile.empTypeID == 6 && this.$storageData.profile.corpBranchID != ""
          ? this.$storageData.profile.corpBranchID
          : 0;
      this.axios
        .post(this.$loggedRole+"/getCorporateCities", {
          corporateID: corporateID,
          corpBranchID: corpBranchID,
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          this.getCorporateBranchByCorpID();
        });
    },

    getCorporateBranchByCorpID() {
      var corporateID =
        this.corporateID != ""
          ? this.corporateID.corporateID
          : this.$storageData.profile.corporateID;
      this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
          corporateID: corporateID,
          city: this.city ? this.city.city : "",
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches[0];
          this.corpBranchID = this.branchID.corpBranchID;
          //Only disabled for super admin
          // this.getCorporateCafeteria(this.corpBranchID);
        });
    },
    onchangeBranch() {
      // this.corpBranchID = this.corpBranchID.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID);
    },
    onchangeRestBranch() {
      // this.restBranch = this.restBranch.restBranchID;
      // this.corpBranchID = (this.$storageData.profile.empTypeID==5) ? this.branchID.corpBranchID: this.$storageData.profile.corpBranchID;
      this.corpBranchID =
        this.corpBranchID != ""
          ? this.corpBranchID.corpBranchID
          : this.$storageData.profile.corpBranchID;
    },
    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readMealPlanReport() {
      this.isTableBusy = true;
      this.isLoading = 1;

      var principleID =
        this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0
          ? this.$storageData.profile.principleID
          : 0;
      var principleBranchID =
        this.$storageData.profile.principleBranchID &&
        this.$storageData.profile.principleBranchID != 0
          ? this.$storageData.profile.principleBranchID
          : this.principleBranchID != ""
          ? this.principleBranchID
          : 0;
      var corpBranchID =
        this.corpBranchID != "" && this.corpBranchID != null
          ? this.corpBranchID.corpBranchID
          : 0;
      this.axios
        .post(this.$loggedRole+"/getMealPlanFullReport", {
          corpBranchID: corpBranchID,
          principleID: principleID,
          principleBranchID: principleBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          transactionType: this.typeID ? this.typeID.typeID : 0,
          dateFilter: this.dateFilter,
          restBranchID:
            this.restBranch != "" && this.restBranch != null
              ? this.restBranch.restBranchID
              : this.$storageData.profile.restBranchID, //this.restBranch.restBranchID,
          restID: this.$storageData.profile.restID,
          loginTypeID: this.$storageData.login_type,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
          princBranchData: this.branches
        })
        .then(response => {
          this.isTableBusy = false;
          this.isLoading = 0;
          this.tableData = response.data.cardData.datatableData;
          this.overViewData = response.data.cardData.overViewData;
          this.cardData = response.data.cardData;
          this.fields.forEach(element => {
            var key = element.key;
            this.excelFields["" + key + ""] = key;
          });
          this.checkForProformaInvoice();
        });
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    formatNumber(num) {
      return Number(num).toLocaleString();
    },
    applyFilter() {
      if (this.dateFilter.includes(null)) {
        this.dateFilter = [];
      }
      // this.corpBranchID = (this.$storageData.profile.empTypeID==5 || this.branchID != "") ? this.corpBranchID.corpBranchID: this.$storageData.profile.corpBranchID;
      this.readMealPlanReport();
      sessionStorage.setItem("mp_CorporateID", this.corporateID.corporateID);
      sessionStorage.setItem("mp_City", this.city.city);
      sessionStorage.setItem("mp_Country", this.country.country);
    },
    clearFilter() {
      this.corporateID = 0;
      this.restBranchArr = [];
      this.city = "";
      this.country = "";
      this.typeID = 0;
      this.corpBranchID = 0;
      this.restBranch = 0;
      this.branchID = 0;
      this.dateFilter = [];
      this.principleBranchID = "";
      // this.isLoading = 1;
      // this.readMealPlanReport();
    },
    getCorporateCafeteria(corpBranchID) {
      this.axios
        .post(this.$loggedRole+"/getCorporateCafeteria", {
          corpBranchID: corpBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          city: this.city ? this.city.city : "",
          country: this.country ? this.country.country : ""
        })
        .then(result => {
          this.cafeteriaArr = result.data.data;
          // this.restBranch = (this.cafeteriaArr) ? this.cafeteriaArr[0] : '';
          this.restBranchID = this.restBranch.restBranchID;
          //alert(this.restBranchID);
          // this.readMealPlanReport();
        });
    },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then(result => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          //  console.log(result.data.data.orderedItemArr);
          this.orderedItemArr = result.data.data.orderedItemArr;
          // this.orderItemAddonArr = result.data.orderItemAddonArr;
        });
    },
    generateProformaInvoice(){

      if(this.city == "" || this.city == null || this.ity=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select City!',
        });
      }
      else if(this.corpBranchID == "" || this.corpBranchID == null || this.corporateID=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Corporate Branch!',
        });
      }
      else if(this.restBranch == "" || this.restBranch == null || this.restBranch=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Restaurant Branch!',
        });
      }
      else if(this.dateFilter == "" || this.dateFilter == null || this.dateFilter=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Date!',
        });
      }
      else{
        this.$root.$emit("bv::show::modal", "modal-lg-proforma");
      }
    },
    submitProformaRequest(){

      if(this.city == "" || this.city == null || this.ity=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select City!',
        });
      }
      else if(this.corpBranchID == "" || this.corpBranchID == null || this.corporateID=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Corporate Branch!',
        });
      }
      else if(this.restBranch == "" || this.restBranch == null || this.restBranch=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Restaurant Branch!',
        });
      }
      else if(this.dateFilter == "" || this.dateFilter == null || this.dateFilter=='undefined'){
        this.$swal({
          icon:'error',
          title:'Generate Proforma Invoice',
          text:'Please Select Date!',
        });
      }
      else {
        this.isTableBusy = true;
        this.isLoading = 1;
        this.axios
            .post(this.$loggedRole + "/generate-meal-plan-proforma-invoice", {
              corporate_branch: this.corpBranchID.corpBranchID,
              dateFilter: this.dateFilter,
              restaurant_branch: this.restBranch.restBranchID,
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
              payment_mode:this.paymentMode,
              order_no:this.orderNo,
              sezSupply:this.sezSupply,
              saleFor:this.saleFor,
            })
            .then((result) => {
              this.isTableBusy = false;
              this.isLoading = 0;
              this.resetForm();
              this.$swal({
                icon: 'success',
                title: 'Generate Proforma Invoice',
                text: result.data.message,
              });
            })
            .catch((error) => {
              this.isTableBusy = false;
              this.isLoading = 0;
              this.$swal({
                icon: 'warning',
                title: 'Generate Proforma Invoice',
                text: error.response.data.message,
              });
            })
      }
    },
    resetForm(){
      this.paymentMode="";
      this.orderNo="";
      this.sezSupply="";
      this.saleFor="";
    },
    checkForProformaInvoice() {
      if (this.corpBranchID == "" || this.corpBranchID == null || this.corporateID == 'undefined' || this.dateFilter == "" || this.dateFilter == null || this.dateFilter == 'undefined') {
        return;
      } else {
        this.isTableBusy = true;
        this.isLoading = 1;
        this.axios.post(this.$loggedRole + "/check-meal-plan-proforma", {
          corporate_branch: this.corpBranchID.corpBranchID,
          dateFilter: this.dateFilter,
          restaurant_branch: this.restBranch.restBranchID,
          country: this.country ? this.country.country : "",
          city: this.city ? this.city.city : "",
        })
            .then((result) => {
              this.isTableBusy = false;
              this.isLoading = 0;
              this.proformaInvoices = result.data.data;
            })
            .catch((error) => {
              this.isTableBusy = false;
              this.isLoading = 0;
              this.$swal({
                icon: 'warning',
                title: 'Check Proforma Invoice',
                text: error.response.data.message,
              });
            });
      }
    },
  },
  middleware: "authentication"
};
</script>

<style scoped>
.my-class {
  max-width: 10px !important;
}

.form-control {
  height: 25px;
  margin-bottom: 5px;
}
.outline {
  border: none;
  background-color: #fff;
}
</style>
